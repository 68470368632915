import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Contracts from "./pages/contracts/contracts";
import ContractsNew from "./pages/contractsnew/contractsnew";
import LoginPage from './pages/loginpage/loginpage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route path="/contracts" element={<Contracts />} />
        <Route path="/contracts/new" element={<ContractsNew />} />
      </Routes>
    </Router>
);
