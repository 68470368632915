const LocalStorage = {
    set: function setLocalStorage(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get: function getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : false;
    },
    destroy: function destroyLocalStorage(key) {
        localStorage.removeItem(key);
    }
};
export default LocalStorage;