import React from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import './contracts.css';
import Contractlist from '../../components/contractlist/contractlist';
// import CheckToken from '../../utils/checktoken';


const Contracts = () => {
    return(
        <>
            <Sidebar />
            <Contractlist />
        </>
    )
}

export default Contracts;
