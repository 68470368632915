import React from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import './contractsnew.css';
import ContractForm from '../../components/contractform/contractform';
// import CheckToken from '../../utils/checktoken';


const ContractsNew = () => {
    return(
        <>
            <Sidebar />
            <ContractForm />
        </>
    )
}

export default ContractsNew;
