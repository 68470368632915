import './contractform.css';
import LocalStorage from '../../utils/localstorage'

const handleSubmit = (e) => {
    e.preventDefault()

    const first_name = document.getElementById("first_name").value;
    const last_name = document.getElementById("last_name").value;
    const title = document.getElementById("title").value;
    const duration = document.getElementById("duration").value;
    const start_date = document.getElementById("start_date").value;
    const price = document.getElementById("price").value;
    const trainer = document.getElementById("trainer").value;

    const contractData = {
        first_name,
        last_name,
        title,
        duration,
        start_date,
        price,
        trainer
    }
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + LocalStorage.get('token')
        },
        body: JSON.stringify(contractData)
    }
    fetch("http://api.skillfacile.com/api/contract/new", options)
    .then((response) => response.json())
    .then((data) => {
        if (data.success) {
            console.log("C'est good")
            document.getElementById("first_name").value = "";
            document.getElementById("last_name").value = "";
            document.getElementById("title").value = "";
            document.getElementById("duration").value = "";
            document.getElementById("start_date").value = "";
            document.getElementById("price").value = "";

            // Afficher un message de réussite
            const successMessage = document.createElement("p");
            successMessage.innerHTML = "Contrat enregistré avec succès";
            successMessage.classList.add("uk-alert-success");
            document.getElementById("contractform").appendChild(successMessage);
        } else {

            const errorMessage = document.createElement("p");
            errorMessage.innerHTML = "Erreur lors de l'enregistrement du contrat";
            errorMessage.classList.add("uk-alert-danger");
            document.getElementById("contractform").appendChild(errorMessage);
            // afficher un message d'erreur
            console.log("C'est pas good")
        }
    })
    .catch((error) => {
        console.error(error);
        // Afficher un message d'erreur
        const errorMessage = document.createElement("p");
        errorMessage.innerHTML = "Erreur lors de l'enregistrement du contrat";
        errorMessage.classList.add("uk-alert-danger");
        document.getElementById("contractform").appendChild(errorMessage);
    });
}



const Contractform = () =>{

    return (
        <>
        <section id="contractform">
            <h1>Ajouter un nouveau contrat</h1>
            <form onSubmit={handleSubmit}>
            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="first_name">Prénom</label>
                <div>
                    <input className="uk-input" id="first_name" type="text" placeholder="Prénom" required />
                </div>
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="last_name">Nom</label>
                <div>
                    <input className="uk-input" id="last_name" type="text" placeholder="Nom" required />
                </div>
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="title">Titre de la formation</label>
                <div>
                    <input className="uk-input" id="title" type="text" placeholder="Titre de la formation" required />
                </div>
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="duration">Durée en h (12)</label>
                <div>
                    <input className="uk-input" id="duration" type="number" placeholder="Durée en h (12)" required />
                </div>
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="start_date">Date d'engagement</label>
                <div>
                    <input className="uk-input" id="start_date" type="date" placeholder="Date d'engagement" required />
                </div>
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="price">Prix de la formation (150)</label>
                <div>
                    <input className="uk-input" id="price" type="number" placeholder="Prix de la formation (150)" required />
                </div>
            </div>

            <div className="uk-margin">
                <label className="uk-form-label" htmlFor="trainer">Formateur</label>
                <div>
                    <select className="uk-select" id="trainer">
                        <option value="Maud Mazingue">Maud Mazingue</option>
                        <option value="Jean-Louis Errante">Jean-Louis Errante</option>
                        <option value="Vincent Errante">Vincent Errante</option>
                    </select>
                </div>
            </div>

            <div className="uk-alert-danger">
                {/* {error} */}
            </div>

            <div className="uk-margin">
                <div className="uk-inline">
                    <span className="uk-form-icon" uk-icon="icon: lock"></span>
                    <button className="uk-button uk-button-default">Enregistrer</button>
                </div>
            </div>
            </form>
        </section>
        </>
    )
}



export default Contractform;