import React from 'react';
import logo from '../../assets/logo-noir.png'
import './sidebar.css';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    return(
        <section className="sidebar">
            <img src={logo} alt="SkillFacile" />
            <nav>
                <ul>
                    <li><NavLink to="/contracts"><span uk-icon="file-text"></span>Les contrats</NavLink></li>
                    <li><NavLink to="/contracts/new"><span uk-icon="pencil"></span>Nouveau contrat</NavLink></li>
                    {/* <li><NavLink to="/settings"><span uk-icon="settings"></span>Paramètres</NavLink></li> */}
                </ul>
            </nav>
        </section>
    )
}

export default Sidebar;
