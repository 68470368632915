import React, { useState } from "react";
import logo from '../../assets/logo-noir.png'
import './loginform.css'
import LocalStorage from "../../utils/localstorage";
import { useNavigate } from 'react-router-dom';


const Loginform = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();


        const data = {
            email,
            password
        }

        fetch('http://api.skillfacile.com/api/login', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => {


            if(data.token){
                LocalStorage.set('token', data.token);
                navigate("/contracts")
                return;
            }

            if(!data.success){
                setError(data.message);
            }

        })
        .catch(err => {
            console.error('Error: ', err)
        })
    }

    return(
        <div>
            <section id="loginForm">
                <img src={logo} alt="SkillFacile" />
                <h1>Connexion au logiciel</h1>
                <form onSubmit={handleSubmit}>
                <div className="uk-margin">
                    <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                        <input className="uk-input" id="email" type="email" placeholder="email" required aria-label="Not clickable icon" onChange={event => setEmail(event.target.value)} />
                    </div>
                </div>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
                        <input className="uk-input" id="password" type="password" placeholder="password" required aria-label="Not clickable icon" onChange={event => setPassword(event.target.value)} />
                    </div>
                </div>

                <div className="uk-alert-danger">
                    {error}
                </div>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: lock"></span>
                        <button className="uk-button uk-button-default">Connexion</button>
                    </div>
                </div>
                

            </form>
            </section>
        </div>
    )
}

export default Loginform;