import { React } from 'react';
import Loginform from '../../components/loginform/loginform';
import './loginpage.css';
import { useNavigate } from 'react-router-dom';

// Utils
import LocalStorage from '../../utils/localstorage';
import { checkToken } from '../../utils/auth';

const loginPage = () => {

    const HandleCheckToken = async () => {
      const navigate = useNavigate();
      const response = await checkToken(LocalStorage.get('token'));
      if (response.error) {
          LocalStorage.destroy('token');
          return;
      } 

      if(response.success) {
        navigate("/contracts")
      }
    }


      if(LocalStorage.get('token')){
        HandleCheckToken()
      }

   
    return(
        <Loginform />
    )
}

export default loginPage;
