import { useState, useEffect } from 'react';
import LocalStorage from '../utils/localstorage';

const useContractsDatas = (url) => {
    const server = 'http://api.skillfacile.com';

    const [isLoading, setLoading] = useState(false)
    const [datas, setDatas] = useState(false)

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            const urlMainData = server + url;
            const dataFetch = async () => {
                const data = await (await fetch(urlMainData, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${LocalStorage.get('token')}`
                    }
                }))
                const json = await data.json();
                setDatas(json);
            }

            dataFetch();
        }
    }, [url, isLoading])
    return { datas }
}

export default useContractsDatas;